import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './styles/App.css';

import MultiStepForm from './pages/MultiStepForm';
import Unauthorized from './pages/Unauthorized';
import withAuth from './utils/auth/withAuth';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path='/:id' component={withAuth(MultiStepForm)} />
          <Route component={Unauthorized} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
