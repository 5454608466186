import { useState } from 'react';
import { Regex_Pattern } from '../types/models';

export function formatDisplayOption(list, selectedList, attr = 'alpha2') {
  return (option) => {
    const index = list.findIndex(val => val[attr] === option);
    const selectedIndex = selectedList.findIndex(val => val === option)
    if (index > -1 && selectedIndex > -1) {
      if (selectedIndex < selectedList.length - 1) {
        return list[index].name + ', '
      } else {
        return list[index].name;
      }
    }
    return option
  }
}

export function SanitizeBrandName() {
  const [brandName, setBrandName] = useState('');

  const handleChange = (event) => {
    setBrandName(event.target.value.replace(Regex_Pattern.remove_special_caracter, ''));
  }
  return (
    <div>
      <input type='text' onChange={handleChange}> value={brandName}</input>
    </div>
  );
}

export const formatDurationString = (value) => {
  if (isValidDurationFormat(value)) {
    return convertDurationInputString(value).toLowerCase().replace(Regex_Pattern.remove_extra_spaces, ' ').trim();
  }
  return value;
}

export const convertDurationInputString = (value) => {
  let replacementValue = value;
  if (value) {
    if (! new RegExp('^(\\d+)-(\\d+)\\s+(h|d|w|m|min).*$', 'i').test(value)) {
      replacementValue = value.replace(Regex_Pattern.convertPaymentOptionsString, '\$1-\$2')
    }
    replacementValue = replacementValue.replace(/\bd.*/gi, 'd')
      .replace(Regex_Pattern.remove_extra_spaces, ' ')
      .replace(/\b(min).*/gi, 'min')
      .replace(/\bm(!in).*/gi, 'm')
      .replace(/\bh.*/gi, 'h')
      .replace(/\bw.*/gi, 'w');
  }
  return replacementValue;
}

export const isValidDurationFormat = (inputString) => {
  const trimmedInput = inputString && inputString.trim();
  const patternToCheck = new RegExp('^(\\d+)-(\\d+)\\s+(h|d|w|m|min)$', 'i');
  if (trimmedInput) {
    return patternToCheck.test(convertDurationInputString(trimmedInput));
  }
  return false;
}