import { Button } from 'primereact/button';
import { LeadObject } from '../utils/types/models';
import { useParams } from 'react-router-dom';
import { useContext, useRef } from 'react';
import SubmitAttempt from '../utils/utility/context';
import { Toast } from 'primereact/toast';
import { db } from '../utils/utility/firebase';
import { Regex_Pattern } from '../utils/types/models';
import { MaxLengthMapper } from '../utils/types/enums';
import { Sanitize } from '../utils/utility/sanitize';
import { useToast } from '../hooks/useToast';

export default function NextButton({
  currentStep,
  setCurrentStep,
  leadDetailsObject,
  contactDetailsObject,
  brandInformationObject,
  brandDetailsObject,
  bankingDetailsObject,
  emailTempInfo,
}) {

  let { id } = useParams();
  let status = 'completed';
  let brandDetailsMergedObject = {
    ...brandDetailsObject.current,
    ...bankingDetailsObject.current,
  };

  const { displayToast, toast } = useToast(useRef(null));
  const { setSubmitAttempted } = useContext(SubmitAttempt);
  const leadObject = LeadObject(
    id,
    leadDetailsObject.current,
    contactDetailsObject.current,
    brandInformationObject.current,
    brandDetailsMergedObject,
    status,
    'anvil_user'
  );

  const handleOnNextButtonClick = () => {
    let newStep = [...currentStep.split("_")];
    switch (newStep[1]) {
      case '0':
        if (leadDetailsObject.current.firstName && leadDetailsObject.current.title) {
          leadObject.leadDetails = leadDetailsObject.current;
          leadObject.status = 'in_progress';
          leadObject.contactInformation = leadObject.contactInformation !== null ? leadObject.contactInformation : {};
          leadObject.contactInformation.email = emailTempInfo;
          db.collection('leads')
            .doc(id)
            .set(leadObject, { merge: true })
            .then(() => {
              newStep[1] = String(Number(newStep[1]) + 1);
              setCurrentStep(newStep.join("_"));
              setSubmitAttempted(false);
            })
            .catch((error) => {
              console.error('Error writing document: ', error);
            })

        } else {
          setSubmitAttempted(true);
          displayToast('error', "Missing fields", "Please verify that all required fields are correctly filled");
        }
        break;
      case '1':
        if (contactDetailsObject.current.companyName
          && contactDetailsObject.current.address_line_one
          && contactDetailsObject.current.city
          && contactDetailsObject.current.email
          && contactDetailsObject.current?.financeEmail
          && contactDetailsObject.current.iso_country_code
          && contactDetailsObject.current.financeEmail
          && contactDetailsObject.current.post_code
          && contactDetailsObject.current.iso_country_code) {
          if (!Regex_Pattern.email.test(contactDetailsObject.current.email)) {
            setSubmitAttempted(true);
            displayToast('error', 'Invalid Email', 'Please enter a valid Email', 'Email');
          } else if (!Regex_Pattern.financeEmail.test(contactDetailsObject.current.financeEmail)) {
            setSubmitAttempted(true);
            displayToast('error', 'Invalid Finance email', 'Please enter a valid Finance email', 'Finance email');
            break;
          } else if ((contactDetailsObject.current.mobile && !Regex_Pattern.phone_number.test(contactDetailsObject.current.mobile)) ||
            (contactDetailsObject.current.phone && !Regex_Pattern.phone_number.test(contactDetailsObject.current.phone))) {
            setSubmitAttempted(true);
            displayToast('error', 'Invalid Phone or mobile', 'Please enter a valid Phone or mobile', 'Phone or mobile');
          }
          else if (
            (contactDetailsObject.current.vat && contactDetailsObject.current.vat.length > MaxLengthMapper.vatMaxLength) ||
            (contactDetailsObject.current.additionalInformation && contactDetailsObject.current.additionalInformation.length > MaxLengthMapper.AdditionalInformationMaxLength) ||
            (contactDetailsObject.current.address_line_two && contactDetailsObject.current.address_line_two.length > MaxLengthMapper.addressMaxLength) ||
            (contactDetailsObject.current.address_line_one === contactDetailsObject.current.address_line_two) ||
            (contactDetailsObject.current.skype && contactDetailsObject.current.skype.length > MaxLengthMapper.skypeMaxLength) ||
            ((contactDetailsObject.current.city && contactDetailsObject.current.city.length > MaxLengthMapper.cityMaxLength) || Sanitize.countSpace(contactDetailsObject.current.city) > 2) ||
            (contactDetailsObject.current.financeEmail.length > MaxLengthMapper.emailMaxLength) ||
            ((!Regex_Pattern.company.test(contactDetailsObject.current.companyName) || contactDetailsObject.current.companyName.length > MaxLengthMapper.companyMaxLength) || Sanitize.countSpace(contactDetailsObject.current.companyName) > 5) ||
            (contactDetailsObject.current.post_code && contactDetailsObject.current.post_code.length > MaxLengthMapper.postCodeMaxLength) ||
            (contactDetailsObject.current.registrationNumber && contactDetailsObject.current.registrationNumber.length > MaxLengthMapper.registrationMaxLength)
          ) {
            setSubmitAttempted(true);
            displayToast('error', 'Invalid Data', 'Please verify that all fields are correctly filled');
            break;
          } else {
            leadObject.contactInformation = contactDetailsObject.current;
            leadObject.status = 'in_progress';
            db.collection('leads')
              .doc(id)
              .set(leadObject, { merge: true })
              .then(() => {
                newStep[1] = String(
                  Number(newStep[1]) + 1
                );
                setCurrentStep(newStep.join('_'));
                setSubmitAttempted(false);
              })
              .catch((error) => {
                console.error(
                  'Error writing document: ',
                  error
                );
              });
          }
        } else {
          setSubmitAttempted(true);
          displayToast('error', 'Missing Fields', "Please verify that all required fields are correctly filled")
        }
        break;
      case '2':
        if (brandInformationObject.current.name && brandInformationObject.current.website && brandInformationObject.current.affiliatePlatformUrl) {
          if (!Regex_Pattern.websiteUrl.test(brandInformationObject.current.website) ||
            (brandInformationObject.current.website).length > MaxLengthMapper.websiteMaxLength ||
            (brandInformationObject.current.name.length > MaxLengthMapper.brandNameMaxLength)
          ) {
            setSubmitAttempted(true);
            displayToast('error', 'Invalid Fields', "Please verify that all required fields are correctly filled");
          } else {
            leadObject.brandInformation = brandInformationObject.current
            leadObject.status = 'in_progress';
            db.collection('leads')
              .doc(id)
              .set(leadObject, { merge: true })
              .then(() => {
                newStep[1] = String(Number(newStep[1]) + 1);
                setCurrentStep(newStep.join("_"));
                setSubmitAttempted(false);
              })
              .catch((error) => {
                console.error('Error writing document: ', error);
              })
          }
        } else {
          setSubmitAttempted(true);
          displayToast('error', 'Missing Fields', "Please verify that all required fields are correctly filled");
        }
        break;
      case '3':
        if (brandDetailsObject.current.product === 'SB') {
          if (!brandDetailsObject.current.gamesProviderSports ||
            brandDetailsObject.current.gamesProviderSports.length === 0) {
            setSubmitAttempted(true);
            displayToast('error', 'Invalid Games Provider Sports', 'Please enter a valid Games Provider Sports', 'Games Provider Sports.')
            break;
          }
        } else if (brandDetailsObject.current.product === 'CA') {
          if (!brandDetailsObject.current.gameCount ||
            brandDetailsObject.current.gameCount === '0' ||
            !brandDetailsObject.current.games ||
            brandDetailsObject.current.games.length === 0 ||
            !brandDetailsObject.current.gamesProviderCasino ||
            brandDetailsObject.current.gamesProviderCasino.length === 0) {
            setSubmitAttempted(true);
            displayToast('error', 'Missing Fields', "Please verify that all required fields are correctly filled");
            break;
          }
        }
        if (brandDetailsObject.current.yearLaunched &&
          brandDetailsObject.current.languagesAvailable &&
          brandDetailsObject.current.availableMarkets &&
          brandDetailsObject.current.licences &&
          brandDetailsObject.current.product) {
          leadObject.brandDetails = brandDetailsObject.current;
          leadObject.status = 'in_progress';
          db.collection('leads')
            .doc(id)
            .set(leadObject, { merge: true })
            .then(() => {
              newStep[1] = String(Number(newStep[1]) + 1);
              setCurrentStep(newStep.join("_"));
              setSubmitAttempted(false);
            })
            .catch((error) => {
              console.error('Error writing document: ', error);
            })
        } else {
          setSubmitAttempted(true);
          displayToast('error', 'Missing Fields', "Please verify that all required fields are correctly filled");
        }
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <Button
        className="button"
        id="next-button"
        label="Next"
        icon="pi pi-angle-right"
        iconPos="right"
        onClick={handleOnNextButtonClick}
      />
    </>
  );
}
