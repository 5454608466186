import logo from '../assets/logo.svg';

export default function NavigationBar() {
  return (
    <>
      <nav className='navbar p-col-12'>
        <div className='p-col p-d-flex p-as-center p-jc-center p-col-3 p-p-0 p-m-0'>
          <img src={logo} alt='logo' width='150' height='26' />
        </div>
      </nav>
    </>
  );
}
