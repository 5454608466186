import personal from '../../assets/personal-info.svg';
import banking from '../../assets/banking.svg';
import contact from '../../assets/contact-details.svg';
import brandInfo from '../../assets/brand-info.svg';
import brandDetails from '../../assets/brand-details.svg';

export default {
    step_0: {
        stepInformation: "PERSONAL INFORMATION",
        progress: 0,
        icon: personal
    },
    step_1: {
        stepInformation: "BILLING AND CONTACT DETAILS",
        progress: 25,
        icon: contact
    },
    step_2: {
        stepInformation: "BRAND INFORMATION",
        progress: 50,
        icon: brandInfo
    },
    step_3: {
        stepInformation: "BRAND DETAILS",
        progress: 75,
        icon: brandDetails
    },
    step_4: {
        stepInformation: "BANKING DETAILS",
        progress: 100,
        icon: banking
    }
};