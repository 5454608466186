import { Button } from "primereact/button";

export default function PreviousButton({ currentStep, setCurrentStep }) {
    return (
        <Button
            className="button"
            id="previous-button"
            label="Previous"
            icon="pi pi-angle-left"
            iconPos="left"
            onClick={() => {
                let newStep = [...currentStep.split("_")];
                newStep[1] = String(Number(newStep[1]) - 1);
                setCurrentStep(newStep.join("_"));
            }}
        />
    );
}
