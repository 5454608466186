import { useContext } from "react";
import SubmitAttempt from "../utils/utility/context";
import { inputAttributes, LeadDetailsObject } from "../utils/types/models";
import { InputText } from "primereact/inputtext";
import { Sanitize } from "../utils/utility/sanitize";
import { useForm } from "react-hook-form";

export default function PersonalInformation({
  firstNameError,
  lastNameError,
  leadDetailsObject,
  currentUser,
}) {
  const { submitAttempted } = useContext(SubmitAttempt);
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: leadDetailsObject?.firstName || currentUser.leadDetails.firstName,
      lastName: leadDetailsObject?.lastName || currentUser.leadDetails.lastName,
      jobTitle: leadDetailsObject?.jobTitle || currentUser.leadDetails.title,
    },
  });

  leadDetailsObject.current = LeadDetailsObject(
    watch("firstName"),
    watch("lastName"),
    watch("jobTitle")
  );

  return (
    <>
      <div className="p-d-flex p-col p-fluid p-ai-center p-pl-3 p-pr-3">
        <div className="p-grid p-col-12">
          <div className="p-field p-col-5">
            <label>First Name *</label>
            <InputText
              type="text"
              className={
                submitAttempted && !getValues("firstName")
                  ? "p-invalid p-inputtext-lg p-d-block input-w-validation"
                  : " p-inputtext-lg p-d-block "
              }
              {...register("firstName", {
                required: 'This field is required',
                maxLength: {
                  value: inputAttributes.nameMaxLength,
                  message: `The max length for this field is ${inputAttributes.nameMaxLength}`,
                },
                onBlur: (event) =>
                  setValue(
                    "firstName",
                    Sanitize.sanitizeName(event.target.value)
                  ),
              })}
            />
            <small className="p-error p-d-block validation-error">
              {firstNameError}
            </small>
          </div>
          <div className="p-field p-col-5">
            <label>Last Name</label>
            <InputText
              type="text"
              className={
                submitAttempted
                  ? "p-inputtext-lg p-d-block"
                  : "p-inputtext-lg p-d-block"
              }
              {...register("lastName", {
                maxLength: {
                  value: inputAttributes.nameMaxLength,
                  message: `The max length for this field is ${inputAttributes.nameMaxLength}`,
                },
                onBlur: (event) =>
                  setValue(
                    "lastName",
                    Sanitize.sanitizeName(event.target.value)
                  ),
              })}
            />
            <small className="p-error p-d-block validation-error">
              {lastNameError}
            </small>
          </div>
          <div className="p-field p-col-5">
            <label>Job Title *</label>
            <InputText
              type="text"
              className={
                submitAttempted && !getValues("jobTitle")
                  ? "p-invalid p-inputtext-lg p-d-block input-w-validation"
                  : " p-inputtext-lg p-d-block "
              }
              {...register("jobTitle", {
                required: true,
                maxLength: {
                  value: inputAttributes.nameMaxLength,
                  message: `The max length for this field is ${inputAttributes.nameMaxLength}`,
                },
                onBlur: (event) =>
                  setValue(
                    "jobTitle",
                    Sanitize.sanitizeTitle(event.target.value)
                  ),
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
}
