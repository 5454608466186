import stepsLabels from "../utils/constants/stepsLabels";

export default function SideBarImage({ currentStep }) {

  return (
    <>
      {
        Object.values(stepsLabels)
          .map((label, index) =>
            <img
              key={`step-image-${index}`}
              className='p-col-12'
              src={label.icon}
              width='150'
              height='150'
              style={{
                display: currentStep === `step_${index}` ? '' : 'none',
              }}
              alt={`side-image${index}`}
            />
          )
      }
    </>
  );
}
