import { useEffect, useContext, useState } from 'react';
import SubmitAttempt from '../utils/utility/context';
import { BrandDetailsObject } from '../utils/types/models';
import { formatDisplayOption } from '../utils/utility/functions';
import { createSelectItems, createDropdownMenuItems, getYearOptions } from '../utils/utility/formHelpers'
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { useForm } from 'react-hook-form';

import MultiSelectModalPopUp from '../components/Multiselect-modal-popup';

export default function BrandDetails({
  currentUser,
  gamesOptions,
  product,
  countries,
  gameProviders,
  licences,
  products,
  brandDetailsObject,
  languages,
}) {
  const { submitAttempted, setSubmitAttempted } = useContext(SubmitAttempt);
  const yearOptions = getYearOptions();
  let casinoProviders = [];
  let sportsProviders = [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  for (let i = 0; i < gameProviders.firestoreData.length; i++) {
    if (gameProviders.firestoreData[i].productNames.includes('Casino') &&
      gameProviders.firestoreData[i].productNames.includes('Sportsbook')) {
      casinoProviders.push(gameProviders.firestoreData[i]);
      sportsProviders.push(gameProviders.firestoreData[i]);
    } else if (gameProviders.firestoreData[i].productNames.includes('Casino')) {
      casinoProviders.push(gameProviders.firestoreData[i]);
    } else if (gameProviders.firestoreData[i].productNames.includes('Sportsbook')) {
      sportsProviders.push(gameProviders.firestoreData[i]);
    }
  }

  function openFromParent(type) {
    setTypeOfObjects(type);
    setOpenModal(true);
  }

  languages = languages.filter(language => language.enabled === true).sort((a, b) => a.name === 'All Languages' ? -1 : a.name.localeCompare(b.name));
  countries = countries.filter(country => country.enabled === true).sort((a, b) => a.name === 'All Countries' ? -1 : a.name.localeCompare(b.name));
  let casinoProvidersSelectItem = createSelectItems(casinoProviders.sort((a, b) => a.name.localeCompare(b.name)));
  casinoProvidersSelectItem = casinoProvidersSelectItem.filter((ele, ind) => ind === casinoProvidersSelectItem.findIndex(elem => elem.value === ele.value))

  const licencesToDisplay = createSelectItems(licences);
  const productsToDisplay = createDropdownMenuItems(products);
  const sportsProvidersSelectItem = createSelectItems(sportsProviders.sort((a, b) => a.name.localeCompare(b.name)));
  const gamesSelectItem = createSelectItems(gamesOptions);
  const languagesSelectItem = createSelectItems(languages);
  const availableMarketsSelectItem = createSelectItems(countries);
  const excludedMarketsSelectItem = createSelectItems(countries);

  const disable = product === 'CA';
  const disableCasinoInit = product === 'CA' ? false : true;
  const disableSportInit = product === 'SB' ? false : true;

  const [openModal, setOpenModal] = useState(false);
  const [disableSports, setDisableSports] = useState(disableSportInit);
  const [disableCasino, setDisableCasino] = useState(disableCasinoInit);
  const [disableGameCount, setDisableGameCount] = useState(!disable);
  const [typeOfObjects, setTypeOfObjects] = useState('');

  const {
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      languagesAvailable: brandDetailsObject?.current?.languagesAvailable || currentUser?.brandDetails?.languagesAvailable || null,
      ecograApproved: brandDetailsObject?.current?.ecograApproved || currentUser?.brandDetails?.ecograApproved || false,
      macVersion: brandDetailsObject?.current?.macVersion || currentUser?.brandDetails?.macVersion || false,
      mobileVersion: brandDetailsObject?.current?.mobileVersion || currentUser?.brandDetails?.mobileVersion || false,
      liveDealer: brandDetailsObject?.current?.liveDealer || currentUser?.brandDetails?.liveDealer || false,
      vipProgram: brandDetailsObject?.current?.vipProgram || currentUser?.brandDetails?.vipProgram || false,
      product: brandDetailsObject?.current?.product || currentUser?.brandDetails?.product || null,
      gamesProviderCasino: brandDetailsObject?.current?.gamesProviderCasino || currentUser?.brandDetails?.gamesProviderCasino || null,
      gamesProviderSports: brandDetailsObject?.current?.gamesProviderSports || currentUser?.brandDetails?.gamesProviderSports || null,
      gamesOptions: brandDetailsObject?.current?.gamesOptions || currentUser?.brandDetails?.gamesOptions || null,
      yearLaunched: brandDetailsObject?.current?.yearLaunched || currentUser?.brandDetails?.yearLaunched || null,
      availableMarkets: brandDetailsObject?.current?.availableMarkets || currentUser?.brandDetails?.availableMarkets || null,
      excludedMarkets: brandDetailsObject?.current?.excludedMarkets || currentUser?.brandDetails?.excludedMarkets || null,
      licences: brandDetailsObject?.current?.licences || currentUser?.brandDetails?.licences || null,
      games: brandDetailsObject?.current?.games || currentUser?.brandDetails?.games || null,
      additionalBrandInformation: brandDetailsObject?.current?.additionalBrandInformation || currentUser?.brandDetails?.additionalBrandInformation || '',
      gameCount: brandDetailsObject?.current?.gameCount || currentUser?.brandDetails?.gameCount || null,
    },
  });

  const inputPopUpLanguages = { languagesSelectItem, selectedLanguages: getValues('languagesAvailable'), }
  const inputPopUpMarkets = { availableMarketsSelectItem, availableMarkets: getValues('availableMarkets') }
  const inputPopUpExcludedMarkets = { excludedMarketsSelectItem, excludedMarkets: getValues('excludedMarkets') }
  const inputPopUpLicences = { licencesToDisplay, selectedLicences: getValues('licences') }
  const inputPopUpGames = { gamesSelectItem, selectedGames: getValues('games') }
  const inputPopUpGamesProviderCasino = { casinoProvidersSelectItem, gamesProviderCasino: getValues('gamesProviderCasino') }
  const inputPopUpGamesProviderSports = { sportsProvidersSelectItem, gamesProviderSports: getValues('gamesProviderSports') }

  brandDetailsObject.current = BrandDetailsObject(
    watch('yearLaunched'),
    watch('availableMarkets'),
    watch('excludedMarkets'),
    watch('licences'),
    watch('gamesProviderSports'),
    watch('gamesProviderCasino'),
    watch('games'),
    watch('additionalBrandInformation'),
    watch('gameCount'),
    watch('vipProgram'),
    watch('liveDealer'),
    watch('mobileVersion'),
    watch('macVersion'),
    watch('ecograApproved'),
    watch('languagesAvailable'),
    watch('product')
  );

  return (
    <>
      <div className='p-d-flex p-col p-fluid p-ai-center'>
        {openModal &&
          <MultiSelectModalPopUp
            IsModalOpened={openModal}
            onCloseModal={() => setOpenModal(false)}
            setValue={setValue}
            inputTypeOfObjects={typeOfObjects}
            inputPopUpLanguages={inputPopUpLanguages}
            inputPopUpMarkets={inputPopUpMarkets}
            inputPopUpExcludedMarkets={inputPopUpExcludedMarkets}
            inputPopUpLicences={inputPopUpLicences}
            inputPopUpGames={inputPopUpGames}
            inputPopUpGamesProviderCasino={inputPopUpGamesProviderCasino}
            inputPopUpGamesProviderSports={inputPopUpGamesProviderSports}
          />
        }
        <div className='p-d-flex'>
          <div className='p-fluid p-col-6 p-grid nested-grid p-pl-4 p-pr-4 p-as-start'>
            <div className='p-field p-col-12'>
              <label>Year Launched *</label>
              <Dropdown
                className={
                  (submitAttempted && !getValues('yearLaunched'))
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('yearLaunched')}
                options={yearOptions}
                onChange={(event) => setValue('yearLaunched', event.value)}
              />
            </div>
            <div className='p-field p-col-12'>
              <label>Languages Available *</label>
              <MultiSelect
                selectedItemTemplate={
                  getValues('languagesAvailable') && getValues('languagesAvailable')?.length > 0
                    ? formatDisplayOption(languages, getValues('languagesAvailable'))
                    : null
                }
                className={
                  submitAttempted &&
                    (!getValues('languagesAvailable') || getValues('languagesAvailable')?.length === 0)
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('languagesAvailable')}
                options={languagesSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent('languagesAvailable')}
                filter
              />
            </div>
            <div className='p-field p-col-12'>
              <label>Available Markets *</label>
              <MultiSelect
                selectedItemTemplate={
                  getValues('availableMarkets') && getValues('availableMarkets')?.length > 0
                    ? formatDisplayOption(countries, getValues('availableMarkets'))
                    : null
                }
                className={
                  submitAttempted &&
                    (!getValues('availableMarkets') || getValues('availableMarkets')?.length === 0)
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('availableMarkets')}
                options={availableMarketsSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent('availableMarkets')}
                filter
              />
            </div>
            <div className='p-field p-col-12'>
              <label>Excluded Markets</label>
              <MultiSelect
                selectedItemTemplate={
                  getValues('excludedMarkets') && getValues('excludedMarkets')?.length > 0
                    ? formatDisplayOption(countries, getValues('excludedMarkets'))
                    : null
                }
                value={getValues('excludedMarkets')}
                options={excludedMarketsSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent('excludedMarkets')}
                filter
              />
            </div>
            <div className='p-field p-col-12'>
              <label>Licences *</label>
              <MultiSelect
                selectedItemTemplate={
                  getValues('licences') && getValues('licences')?.length > 0
                    ? formatDisplayOption(licences, getValues('licences'), 'code')
                    : null
                }
                className={
                  submitAttempted &&
                    (!getValues('licences') || getValues('licences')?.length === 0)
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('licences')}
                options={licencesToDisplay}
                optionLabel="label"
                onFocus={() => openFromParent('licences')}
                filter
              />
            </div>
            <div className='p-field p-col-12'>
              <label>Product *</label>
              <Dropdown
                className={
                  submitAttempted && (!getValues('product'))
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('product')}
                options={productsToDisplay}
                optionLabel="label"
                optionValue="code"
                onChange={(event) => {
                  setSubmitAttempted(false);
                  if (event.value !== 'CA' && event.value !== 'SB') {
                    setDisableSports(true);
                    setDisableCasino(true);
                    setDisableGameCount(true);
                    setValue('gamesProviderSports', []);
                    setValue('gamesProviderCasino', []);
                    setValue('gameCount', null);
                    setValue('games', []);
                  }
                  if (event.value === 'CA') {
                    setDisableSports(true);
                    setDisableCasino(false);
                    setDisableGameCount(false);
                    setValue('games', []);
                    setValue('gamesProviderSports', []);
                    setValue('gamesProviderCasino', []);
                  }
                  if (event.value === 'SB') {
                    setDisableSports(false);
                    setDisableCasino(true);
                    setDisableGameCount(true);
                    setValue('games', []);
                    setValue('gamesProviderSports', []);
                    setValue('gamesProviderCasino', []);
                    setValue('gameCount', null);
                  }
                  setValue('product', event.value)
                }}
                filter
              />
            </div>
            <div className='p-field p-col-12'>
              <label>Games Available {getValues('product') === 'CA' ? '*' : ''}</label>
              <MultiSelect
                selectedItemTemplate={getValues('games') && getValues('games')?.length > 0 ? formatDisplayOption(gamesOptions, getValues('games'), 'code') : null}
                className={
                  submitAttempted && getValues('games')?.length === 0 && getValues('product') === 'CA'
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('games')}
                options={gamesSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent('games')}
                disabled={disableCasino}
                filter
              />
            </div>
          </div>

          <div className='p-fluid p-col-6 p-grid nested-grid p-pl-4 p-pr-4'>
            <div className='p-col-12 p-field'>
              <label>Game Count {getValues('product') === 'CA' ? '*' : ''}</label>
              <InputText
                className={
                  submitAttempted && getValues('product') === 'CA' && (!getValues('gameCount') || getValues('gameCount') === '')
                    ? 'p-invalid input-w-validation p-inputtext-lg p-d-block'
                    : 'p-inputtext-lg p-d-block'
                }
                value={getValues('product') !== 'CA' ? 0 : getValues('gameCount')}
                onChange={(event) => setValue('gameCount', event.target.value)}
                disabled={disableGameCount}
              />
            </div>
            <div className='p-field p-col-12'>
              <label>Games Provider Casino {getValues('product') === 'CA' ? '*' : ''}</label>
              <MultiSelect
                selectedItemTemplate={
                  getValues('gamesProviderCasino') && getValues('gamesProviderCasino')?.length
                    ? formatDisplayOption(casinoProviders, getValues('gamesProviderCasino'), 'code')
                    : null
                }
                className={
                  submitAttempted && getValues('product') === 'CA' && getValues('gamesProviderCasino')?.length === 0
                    ? 'p-invalid'
                    : ''
                }
                value={getValues('gamesProviderCasino')}
                options={casinoProvidersSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent('gamesProviderCasino')}
                disabled={disableCasino}
                filter
              />
            </div>
            <div className='p-field p-col-12'>
              <label >Games Provider Sports {getValues('product') === 'SB' ? '*' : ''}</label>
              <MultiSelect
                selectedItemTemplate={
                  getValues('gamesProviderSports') && getValues('gamesProviderSports')?.length > 0
                    ? formatDisplayOption(sportsProviders, getValues('gamesProviderSports'), 'code')
                    : null
                }
                className={
                  submitAttempted && getValues('product') === 'SB' && getValues('gamesProviderSports')?.length <= 0
                    ? 'p-invalid'
                    : ''
                }
                filter
                value={getValues('gamesProviderSports')}
                options={sportsProvidersSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent('gamesProviderSports')}
                disabled={disableSports}
              />
            </div>

            <div className='p-field p-col-12'>
              <div className='p-grid'>
                <div className='p-field p-col-6 p-d-flex p-ai-center p-jc-start'>
                  <Checkbox
                    className='p-mr-2'
                    onChange={(event) => setValue('vipProgram', event.checked)}
                    checked={getValues('vipProgram')}>
                  </Checkbox>
                  <span>VIP Program</span>
                </div>
                <div className='p-field p-col-6 p-d-flex p-ai-center p-jc-start'>
                  <Checkbox
                    className='p-mr-2'
                    onChange={(event) => setValue('liveDealer', event.checked)}
                    checked={getValues('liveDealer')}>
                  </Checkbox>
                  <span>Live Dealer</span>
                </div>
                <div className='p-field p-col-6 p-d-flex p-ai-center p-jc-start'>
                  <Checkbox
                    className='p-mr-2'
                    onChange={(event) => setValue('macVersion', event.checked)}
                    checked={getValues('macVersion')}>
                  </Checkbox>
                  <span>Mac Version</span>
                </div>
                <div className='p-field p-col-6 p-d-flex p-ai-center p-jc-start'>
                  <Checkbox
                    className='p-mr-2'
                    onChange={(event) => setValue('mobileVersion', event.checked)}
                    checked={getValues("mobileVersion")}>
                  </Checkbox>
                  <span>Mobile Version</span>
                </div>
                <div className='p-field p-col-6 p-d-flex p-ai-center p-jc-start'>
                  <Checkbox
                    className='p-mr-2'
                    onChange={(event) => setValue('ecograApproved', event.checked)}
                    checked={getValues('ecograApproved')}>
                  </Checkbox>
                  <span>Ecogra Certified</span>
                </div>
              </div>
            </div>
            <div className='p-field p-col-12'>
              <label>Additional Brand Information</label>
              <InputTextarea
                rows={5}
                cols={30}
                value={getValues('additionalBrandInformation')}
                onChange={(event) => setValue('additionalBrandInformation', event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
