import { useState, useRef } from "react";
import useFirestore from "../hooks/useFirestore";
import { motion } from "framer-motion";
import SubmitAttempt from "../utils/utility/context";
import BankingDetails from "../layouts/BankingDetails";
import BrandDetails from "../layouts/BrandDetails";
import BrandInformation from "../layouts/BrandInformation";
import ContactDetails from "../layouts/ContactDetails";
import PersonalInformation from "../layouts/PersonalInformation";
import SideBar from "../components/SideBar";
import NextButton from "../components/NextButton";
import SubmitButton from "../components/SubmitButton";
import PreviousButton from "../components/PreviousButton";
import NavigationBar from "../components/NavigationBar";

export default function MultiStepForm({ currentUser }) {
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const value = { submitAttempted, setSubmitAttempted };
  const currentUserStatus =
    currentUser.status === "requested" || currentUser.status === "in_progress"
      ? "step_0"
      : currentUser.status;
  const [currentStep, setCurrentStep] = useState(currentUserStatus);
  const currencies = useFirestore("currencies");
  const paymentMethods = useFirestore("payment-methods");
  const countries = useFirestore("countries");
  const gameProviders = useFirestore("game-providers");
  const licences = useFirestore("casino-licences");
  const products = useFirestore("products");
  const languages = useFirestore("languages");
  const gamesOptions = useFirestore("brand-details-games");

  const product =
    currentUser.brandDetails.product !== undefined
      ? currentUser.brandDetails.product
      : null;

  const navigation = {
    currentStep,
    setCurrentStep,
  };

  const leadDetailsObject = useRef(null);
  const contactDetailsObject = useRef(null);
  const brandInformationObject = useRef(null);
  const brandDetailsObject = useRef(null);
  const bankingDetailsObject = useRef(null);

  brandInformationObject.current = brandInformationObject.current ? brandInformationObject.current : currentUser.brandInformation;
  brandDetailsObject.current = brandDetailsObject.current ? brandDetailsObject.current : currentUser.brandDetails;

  const componentToDisplay = {
    step_0: (
      <PersonalInformation
        leadDetailsObject={leadDetailsObject}
        currentUser={currentUser}
      />
    ),
    step_1: (
      <ContactDetails
        currentUser={currentUser}
        contactDetailsObject={contactDetailsObject}
        countries={countries.firestoreData.sort((a, b) =>
          a.name.localeCompare(b.name)
        )}
      />
    ),
    step_2: (
      <BrandInformation
        currentUser={currentUser}
        brandInformationObject={brandInformationObject}
      />
    ),
    step_3: (
      <BrandDetails
        currentUser={currentUser}
        gameProviders={gameProviders}
        brandDetailsObject={brandDetailsObject}
        product={product}
        gamesOptions={gamesOptions.firestoreData.sort((a, b) =>
          a.name.localeCompare(b.name)
        )}
        languages={languages.firestoreData.sort((a, b) =>
          a.name.localeCompare(b.name)
        )}
        countries={countries.firestoreData.sort((a, b) =>
          a.name.localeCompare(b.name)
        )}
        licences={licences.firestoreData.sort((a, b) =>
          a.name.localeCompare(b.name)
        )}
        products={products.firestoreData.sort((a, b) =>
          a.name.localeCompare(b.name)
        )}
      />
    ),
    step_4: (
      <BankingDetails
        bankingDetailsObject={bankingDetailsObject}
        allCurrencies={currencies}
        paymentMethods={paymentMethods}
      />
    ),
  };

  return (
    <>
      <SubmitAttempt.Provider value={value}>
        <motion.div
          className="p-d-flex p-col-12 p-p-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
        >
          <NavigationBar />
          <div className="sidebar p-d-flex p-ac-center p-jc-center p-col-3">
            <SideBar currentStep={currentStep} />
          </div>
          <div className="main p-d-flex p-flex-column p-jc-between p-p-2 p-offset-3 p-col-9">
            {componentToDisplay[currentStep]}
            <div className="p-d-flex p-as-end button-container">
              <div className="p-col-6">
                {currentStep !== "step_0" && <PreviousButton {...navigation} />}
              </div>
              <div className="p-col-6">
                {currentStep !== "step_4" ? (
                  <NextButton
                    {...navigation}
                    leadDetailsObject={leadDetailsObject}
                    contactDetailsObject={contactDetailsObject}
                    brandInformationObject={brandInformationObject}
                    brandDetailsObject={brandDetailsObject}
                    bankingDetailsObject={bankingDetailsObject}
                    emailTempInfo={currentUser.contactInformation.email}
                  />
                ) : (
                  <SubmitButton
                    leadDetailsObject={leadDetailsObject}
                    contactDetailsObject={contactDetailsObject}
                    brandInformationObject={brandInformationObject}
                    brandDetailsObject={brandDetailsObject}
                    bankingDetailsObject={bankingDetailsObject}
                  />
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </SubmitAttempt.Provider>
    </>
  );
}
