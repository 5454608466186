export const MaxLengthMapper = {
    addressMaxLength: 150,
    websiteMaxLength: 200,
    AddresLineMaxlength: 150,
    skypeMaxLength: 50,
    AdditionalInformationMaxLength: 500,
    vatMaxLength: 15,
    registrationMaxLength: 15,
    brandNameMaxLength: 60,
    cityMaxLength: 50,
    companyMaxLength: 100,
    postCodeMaxLength: 10,
    emailMaxLength: 100,
}