import { ProgressBar } from "primereact/progressbar";
import SideBarImage from "./SideBarImage";
import { StepsLabel } from "../utils/constants";

export default function SideBar({ currentStep }) {

    return (
        <div className="p-d-flex p-flex-column p-col-12 p-p-4 p-jc-between">
            <div></div>
            <div>
                <SideBarImage currentStep={currentStep} />

                <h2 className="p-text-center">
                    {StepsLabel[currentStep].stepInformation}
                </h2>
            </div>
            <div>
                <div className="p-col-12 p-as-center p-text-center">
                    <label className="progress">Progress</label>
                </div>
                <ProgressBar
                    value={StepsLabel[currentStep].progress}
                ></ProgressBar>
            </div>
        </div>
    );
}