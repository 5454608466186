import { Sanitize } from '../utils/utility/sanitize';
import { inputAttributes } from '../utils/types/models';

export function useCurrencyInput(setValue, index, currenciesDetails, currency) {
    const { sanitizeCrypto, sanitizeCurrency } = Sanitize
    const handleOnMinimumDepositChange = (event) => {
        let updatedCurrencies = [...currenciesDetails];
        if (currency === 'BTC' || currency.currency === 'MBTC') {
            updatedCurrencies[index].minimumDeposit = sanitizeCrypto(event.target.value);
            setValue('currencyDetails', updatedCurrencies);
        } else {
            updatedCurrencies[index].minimumDeposit = sanitizeCurrency(event.target.value);
            setValue('currencyDetails', updatedCurrencies);
        }
    }

    const handleOnCashoutLimitChange = (event) => {
        let updatedCurrencies = [...currenciesDetails];
        if (currency.currency === 'BTC' || currency.currency === 'MBTC') {
            updatedCurrencies[index].cashoutLimit = sanitizeCrypto(event.target.value);
            setValue('currencyDetails', updatedCurrencies);
        } else {
            updatedCurrencies[index].cashoutLimit = sanitizeCurrency(event.target.value);
            setValue('currencyDetails', updatedCurrencies);
        }
    }

    const handleOnInputBlur = (event) => {
        (currency.currency !== 'BTC' || currency.currency !== 'MBTC')
            ? sanitizeCurrency(event.target.value)
            : sanitizeCrypto(event.target.value);
    }

    const numberInputProps = {
        type: 'number',
        maxLength: inputAttributes.currencyMaxLength,
        onBlur: handleOnInputBlur
    }

    return { numberInputProps, handleOnInputBlur, handleOnMinimumDepositChange, handleOnCashoutLimitChange }
}