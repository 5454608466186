import { useState, useEffect } from "react";
import { db } from "../utils/utility/firebase";

export default function useFirestore(document) {
    const [firestoreData, setFirestoreData] = useState([]);

    useEffect(() => {
        let unsubscribe;
        if (document) {
            const ref = db.collection("at-settings").doc(document);
            unsubscribe = ref.onSnapshot((doc) => {
                setFirestoreData(doc.data().values);
            });
        } else {
            setFirestoreData(null);
        }
        return unsubscribe;
    }, [document]);
    return { firestoreData };
}
