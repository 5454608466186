import { useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import SubmitAttempt from "../utils/utility/context";
import { LeadObject } from "../utils/types/models";
import { db } from "../utils/utility/firebase";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useToast } from "../hooks/useToast";

export default function SubmitButton({
  leadDetailsObject,
  contactDetailsObject,
  brandInformationObject,
  brandDetailsObject,
  bankingDetailsObject,
}) {
  let { id } = useParams();

  const { displayToast, toast } = useToast(useRef(null));
  const { setSubmitAttempted } = useContext(SubmitAttempt);
  const status = "completed";
  const brandDetailsMergedObject = {
    ...brandDetailsObject.current,
    ...bankingDetailsObject.current,
  };
  const leadObject = LeadObject(
    id,
    leadDetailsObject.current,
    contactDetailsObject.current,
    brandInformationObject.current,
    brandDetailsMergedObject,
    status,
    "anvil_user"
  );

  const { firstName, lastName } = leadObject.leadDetails;
  const { name, website, affiliatePlatformUrl } = leadObject.brandInformation;
  const {
    email,
    financeEmail,
    companyName,
    address_line_one,
    city,
    post_code,
  } = leadObject.contactInformation;
  const {
    yearLaunched,
    availableMarkets,
    licences,
    languagesAvailable,
  } = leadObject.brandDetails;

  const handleOnSubmitButtonClick = () => {
    leadObject.brandDetails = { ...brandDetailsObject.current, ...bankingDetailsObject.current }
    const { product, gameCount } = brandDetailsObject.current
    const { currencyDetails, paymentMethodsDeposit, paymentMethodsWithdrawal } = bankingDetailsObject.current;

    if (product === 'CA') {
      if (gameCount === "") {
        setSubmitAttempted(true);
        displayToast("error", "Missing fields");
      }
    }

    let currencyRequired = true;
    let currencyItems = 0;
    let currencyItemsRequired = true;

    currencyRequired = currencyDetails.length > 0;

    if (currencyDetails.length !== 0) {
      for (let i = 0; i < currencyDetails.length; i++) {
        if (currencyDetails[i].minimumDeposit.toString().trim() === "") {
          currencyItems = currencyItems + 1;
        }
      }
    }

    if (currencyItems > 0) {
      currencyItemsRequired = false;
    }

    const { currencies } = bankingDetailsObject?.current;
    const checkPaymentMethodsDeposit =
      paymentMethodsDeposit === null || paymentMethodsDeposit.length === 0
        ? false
        : true;
    const checkPaymentMethodsWithdrawal =
      paymentMethodsWithdrawal === null || paymentMethodsWithdrawal.length === 0
        ? false
        : true;

    const shouldSubmitForm = firstName && lastName && email && financeEmail && companyName && address_line_one &&
      city && post_code && name && website && affiliatePlatformUrl && languagesAvailable &&
      yearLaunched && availableMarkets && licences && checkPaymentMethodsDeposit &&
      checkPaymentMethodsWithdrawal && currencies && currencyItemsRequired &&
      currencyRequired && currencyDetails

    setSubmitAttempted(true);
    if (shouldSubmitForm) {
      db.collection("leads")
        .doc(id)
        .set(leadObject, { merge: true })
        .then(() => {
          console.log("Done.");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      displayToast(
        "error",
        "Missing fields",
        "Please verify that all required fields are correctly filled"
      );
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Button
        className="button"
        id="submit-button"
        label="Submit"
        icon="pi pi-check"
        iconPos="right"
        onClick={handleOnSubmitButtonClick}
      />
    </>
  );
}
