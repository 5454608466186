import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';

import "../styles/modal.css";

export default function MultiSelectModalPopUp(props) {
    const { setValue, inputTypeOfObjects, IsModalOpened } = props;

    const dialogFuncMap = {
        'languagesAvailable': props.inputPopUpLanguages,
        'availableMarkets': props.inputPopUpMarkets,
        'excludedMarkets': props.inputPopUpExcludedMarkets,
        'licences': props.inputPopUpLicences,
        'games': props.inputPopUpGames,
        'gamesProviderCasino': props.inputPopUpGamesProviderCasino,
        'gamesProviderSports': props.inputPopUpGamesProviderSports,
        'paymentMethodsDeposit': props.inputPopUpPaymentsDeposit,
        'paymentMethodsWithdrawal': props.inputPopUpPaymentsWithdraw
    }

    const mapItem = dialogFuncMap[`${inputTypeOfObjects}`];

    const customObjectMap = {
        'languagesAvailable': {
            selected: mapItem.selectedLanguages,
            selectItems: mapItem.languagesSelectItem,
        },
        'availableMarkets': {
            selected: mapItem.availableMarkets,
            selectItems: mapItem.availableMarketsSelectItem,
        },
        'excludedMarkets': {
            selected: mapItem.excludedMarkets,
            selectItems: mapItem.excludedMarketsSelectItem,
        },
        'licences': {
            selected: mapItem.selectedLicences,
            selectItems: mapItem.licencesToDisplay,
        },
        'games': {
            selected: mapItem.selectedGames,
            selectItems: mapItem.gamesSelectItem,
        },
        'gamesProviderCasino': {
            selected: mapItem.gamesProviderCasino,
            selectItems: mapItem.casinoProvidersSelectItem,
        },
        'gamesProviderSports': {
            selected: mapItem.gamesProviderSports,
            selectItems: mapItem.sportsProvidersSelectItem,
        },
        'paymentMethodsDeposit': {
            selected: mapItem.paymentMethodsDeposit,
            selectItems: mapItem.paymentMethodsDepositSelectItem,
        },
        'paymentMethodsWithdrawal': {
            selected: mapItem.paymentMethodsWithdrawal,
            selectItems: mapItem.paymentMethodsWithdrawalSelectItem,
        }
    }

    const itemSelectItem = customObjectMap[inputTypeOfObjects].selectItems;
    const inputSelectedItems = customObjectMap[inputTypeOfObjects].selected || ''
    const [items, setItems] = useState(inputSelectedItems);

    const onHide = () => {
        props.onCloseModal();
    }

    const onClickChange = (e) => {
        let selectedItems = [...items];

        if (e.checked)
            selectedItems.push(e.value);
        else
            selectedItems.splice(selectedItems.indexOf(e.value), 1);

        setItems(selectedItems);
        setValue(inputTypeOfObjects, selectedItems);
    }

    const getPopupHeader = (input) => {
        let headers = {
            'languagesAvailable': 'Languages Available',
            'availableMarkets': 'Available Markets',
            'excludedMarkets': 'Excluded Markets',
            'licences': 'Licences',
            'games': 'Games',
            'gamesProviderCasino': 'Games Provider Casino',
            'gamesProviderSports': 'Games Provider Sports',
            'paymentMethodsDeposit': 'Payment Methods Deposit',
            'paymentMethodsWithdrawal': 'Payment Methods Withdrawal'
        }
        return headers[input]
    }

    return (
        <Dialog header={getPopupHeader(inputTypeOfObjects)} visible={IsModalOpened} style={{ width: '50vw' }} onHide={() => onHide('displayBasic')}>
            {itemSelectItem && <div className="p-grid options-layout p-mt-4">
                {itemSelectItem.map(item =>
                    <div key={`${item.value}-${item.label}`} className='p-fluid p-col-6 p-grid nested-grid p-pl-4 p-pr-4 p-as-start'>
                        <div className="p-col-12" key={item.value}>
                            <Checkbox value={item.value} onChange={e => onClickChange(e)} checked={items.includes(item.value)}></Checkbox>
                            <label htmlFor={item.label} className="p-checkbox-label p-ml-2">{item.label}</label>
                        </div>
                    </div>
                )}
            </div>
            }
        </Dialog>

    );
}