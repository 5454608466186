/**
 * @param {React.MutableRefObject<T>} toastRef - the initial value for the toast's ref ex: useRef(null)
 * @returns a displayToast function along with the toast ref.
 */
export const useToast = (toastRef) => {


    const displayToast = (type, summary = null, detail = null, field = null) => {

        if (type.toLowerCase().trim() === "error") {
            if (!summary) {
               summary = (field) ? `Missing fields` : `Something went wrong`;
            }

            if (!detail && field) {
                summary = summary ? summary : `Invalid ${field}`;
                detail = `Please enter a valid ${field}`;
            } else if (!(detail && field)) {
                detail = "Please verify that all required fields are correctly filled"
            }
        }

        toastRef.current.show({
            severity: type.toLowerCase().trim(),
            summary: summary,
            detail: detail,
            sticky: false,
            life: 3000,
        })

    }

    return {
        displayToast,
        toast: toastRef
    };
}
