export function getCurrencies(input, field = "currency") {
    let output = [];
    for (let i = 0; i < input.length; i++) output.push(input[i][field]);
    return output;
}

export function createSelectItems(fullArray) {
    let result = [];
    for (let index = 0; index < fullArray.length; index++) {
        if (fullArray[index].name && (fullArray[index].code || fullArray[index].alpha2)) {
            let selectItem = {
                label: fullArray[index].name,
                value: fullArray[index].code ?? fullArray[index].alpha2
            };
            result.push(selectItem)
        }
    }
    return result;
}


export function createDropdownMenuItems(fullArray) {
    let result = [];
    for (let index = 0; index < fullArray.length; index++) {
        let selectItem = { label: fullArray[index].name, code: fullArray[index].code };
        result.push(selectItem)
    }
    return result;
}

export function getYearOptions() {
    const result = [];
    const maxYearValue = (new Date()).getFullYear();
    const minYearValue = maxYearValue - 15;

    for (let index = maxYearValue; index >= minYearValue; index--) {
        const yearOption = index;
        result.push(yearOption);
    }
    return result;
}
