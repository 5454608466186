import { useContext } from "react";
import SubmitAttempt from "../utils/utility/context";

import { useCurrencyInput } from "../hooks/useCurrencyInput";

export default function CurrencyInformation({
  currency,
  currenciesDetails,
  setValue,
  index,
}) {
  const { submitAttempted } = useContext(SubmitAttempt);
  const {
    numberInputProps,
    handleOnMinimumDepositChange,
    handleOnCashoutLimitChange,
  } = useCurrencyInput(setValue, index, currenciesDetails, currency);

  return (
    <div className="p-d-flex p-col-12 ">
      <div className="p-field p-col-6">
        <label>
          <b>{currency.currency}</b> Minimum Deposit *
        </label>
        <input
          {...numberInputProps}
          value={currency.minimumDeposit}
          onChange={handleOnMinimumDepositChange}
          className={
            submitAttempted && !currency.minimumDeposit
              ? "p-invalid p-inputtext-lg p-d-block input-w-validation"
              : " p-inputtext-lg p-d-block "
          }
        />
      </div>

      <div className="p-field p-col-6">
        <label>
          <b>{currency.currency}</b> Monthly Cashout Limit
        </label>
        <input
          {...numberInputProps}
          value={currency.cashoutLimit}
          onChange={handleOnCashoutLimitChange}
        />
      </div>
    </div>
  );
}
