import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../utility/firebase';
import { auth } from '../utility/firebase';
import LoadingPage from '../../components/LoadingPage';
import Unauthorized from '../../pages/Unauthorized';
import SubmitionConfirmation from '../../components/SubmitionConfirmation';

const withAuth = (Component) => () => {
  let { id } = useParams();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    let unsubscribe;
    if (id) {
      const ref = db.collection('leads').doc(id);
      unsubscribe = ref.onSnapshot(
        (doc) => {
          if (doc) {
            setCurrentUser(doc.data());
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          }
        },
        (error) => {
          setCurrentUser(null);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  currentUser !== null &&
    auth
      .signInAnonymously()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode + errorMessage);
      });

  function toDateTime(secs) {
    const time = new Date(1970, 0, 1);
    time.setSeconds(secs);
    return time;
  }

  if (loading) {
    return <LoadingPage />;
  }
  if (currentUser !== null) {
    if (
      isLoggedIn &&
      toDateTime(currentUser.linkExpiryDate.seconds) > new Date() &&
      (currentUser.status === 'requested' || currentUser.status === 'in_progress')
    ) {
      return <Component currentUser={currentUser} />;
    } else if (isLoggedIn && (currentUser.status === 'completed' || currentUser.status === 'approved')) {
      return <SubmitionConfirmation />;
    }
  }
  if (currentUser === null) {
    return <Unauthorized />;
  }
};

export default withAuth;
