import { Regex_Pattern } from '../types/models';

export const Sanitize = (function () {
  const sanitizeWebsite = (element) => {
    element = element?.replace(Regex_Pattern.websiteSpecialCharacters, '');
    return element;
  };

  const checkUppercase = (str) => {
    return Regex_Pattern.checkUppercaseLetter.test(str);
  };

  const removeAllSpecialCharacters = (element) => {
    element = element?.replace(Regex_Pattern.allSpecialCharacters, '');
    element = element?.replace(Regex_Pattern.removeMultipleSpaces, ' ').trim();
    return element;
  };

  const removeSpace = (element) => {
    element = element?.replace(Regex_Pattern.removeMultipleSpaces, ' ').trim();
    return element;
  };

  const sanitizeName = (value) => {
    let inputValue = value.trim();
    inputValue = inputValue.replace(Regex_Pattern.name, '');
    inputValue = inputValue.replace(Regex_Pattern.remove_double_chars, ' ');
    inputValue = inputValue.replace(/[\-]+/g, '-');
    return inputValue;
  };

  const sanitizeTitle = (value) => {
    let inputValue = value.trim();
    inputValue = inputValue.replace(Regex_Pattern.title, '');
    inputValue = inputValue.replace(Regex_Pattern.remove_double_chars, ' ');
    return inputValue;
  };

  const SanitizeBrandName = (element) => {
    element = element.replace(Regex_Pattern.remove_special_caracter, '');
    element = element?.replace(Regex_Pattern.removeMultipleSpaces, ' ');
    element = element.replace(/[!!]+/g, '!');
    element = element.replace(/[__]+/g, '_');
    element = element.replace(/[@@]+/g, '@');
    element = element.replace(/[--]+/g, '-');
    return element;
  };

  const sanitizePhone = (phone) => {
    phone = phone?.replace(Regex_Pattern.phoneSpecialCharacters, '');
    phone = phone?.replace(Regex_Pattern.remove_extra_spaces, '');
    return phone;
  };

  const sanitizeAddress = (value) => {
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/gi, '');
    inputValue = inputValue.replace(Regex_Pattern.remove_extra_spaces, ' ');
    return inputValue;
  };

  const removeSpecialCharacters = (value) => {
    let inputValue = value.replace(Regex_Pattern.registrationNumber, '');
    inputValue = inputValue.replace(Regex_Pattern.remove_extra_spaces, '');
    return inputValue;
  };

  const sanitizeSkype = (value) => {
    return value.replace(Regex_Pattern.remove_extra_spaces, ' ').trim();
  };

  const sanitizeCity = (value) => {
    let inputValue = value.toLowerCase();
    if (inputValue.length > 0) {
      inputValue.charAt(0).replace(inputValue.charAt(0), inputValue[0].charAt(0).toUpperCase());
      inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    }

    return inputValue.replace(Regex_Pattern.remove_extra_spaces, ' ');
  };
  
  const sanitizePostalCode = (value) => {
    let inputValue = value.toUpperCase();

    return inputValue = inputValue.replace(Regex_Pattern.postalCodeSpecialChars, '');
  };

  const sanitizeCompanyName = (element) => {
    element = element?.replace(Regex_Pattern.companySpecialChars, '');
    element = element?.replace(Regex_Pattern.removeMultipleSpaces, ' ');
    element = element.replace(/[--]+/g, '-').trim();
    return element;
};

const sanitizeEmail = (element) => {
  element = element?.replace(Regex_Pattern.financeEmail, '');
  element = element?.replace(Regex_Pattern.removeMultipleSpaces, ' ');
  element = element.replace(/[..]+/g, '.');
  element = element.replace(/[@@]+/g, '@');
  element = element.replace(/[_]+/g, '_');
  return element;
};

const countSpace = (value) => {
  const element = value?.trim();
  const numSpace = element?.split(' ').length - 1;
  return numSpace;
};

const sanitizeCrypto = (value) => {
  if(value.charAt(value.length - 1) === '.')  {
    value.substr(0, value.length - 1);
  }
  return value;
}

const sanitizeCurrency = (value) => {
 let currency = value.toString().replace(/[.?]+/g, '');
  currency = parseInt(currency);
  return currency;
}

  return {
    sanitizeName,
    sanitizeTitle,
    sanitizeAddress,
    removeSpecialCharacters,
    sanitizeSkype,
    sanitizeCity,
    countSpace, 
    sanitizeEmail, 
    sanitizeCompanyName,
    sanitizePostalCode,
    sanitizeCurrency,
    sanitizeCrypto,
    SanitizeBrandName: SanitizeBrandName,
    sanitizePhone: sanitizePhone,
    removeAllSpecialCharacters: removeAllSpecialCharacters,
    removeSpace: removeSpace,
    sanitizeWebsite: sanitizeWebsite,
    checkUppercase: checkUppercase,
  };
})();
