import error404 from '../assets/error-404.svg';
import NavigationBar from '../components/NavigationBar';
import { motion } from 'framer-motion';

export default function Unauthorized() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,

        transition: {
          duration: 1,
        },
      }}
      exit={{ opacity: 1 }}>
      <NavigationBar />
      <div className='sidebar p-col-3'></div>
      <div className='main p-offset-3 p-col-9 p-d-flex p-ai-center p-jc-center'>
        <img src={error404} alt='error 404' id='image-error' />
      </div>
    </motion.div>
  );
}
