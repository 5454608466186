import logo from '../assets/logo.svg';
import { motion } from 'framer-motion';
import { fadeInAnimation } from "../utils/constants";

export default function SubmitionConfirmation() {
  return (
    <motion.div
      className='submit-confirmation'
      {...fadeInAnimation} >
      <img id='image-loading' src={logo} alt='logo loading' />
      <h1>Thank you for submitting the form</h1>
      <h3>We will get back to you very shortly</h3>
    </motion.div>
  );
}
