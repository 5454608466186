import logo from '../assets/logo.svg';
import { motion } from 'framer-motion';

export default function LoadingPage() {
  return (
    <motion.div
      className='loading-wrapper'
      initial={{ opacity: 1 }}
      animate={{
        opacity: 0,

        transition: {
          duration: 1.5,
        },
      }}
      exit={{ opacity: 0 }}>
      <img id='image-loading' src={logo} alt='logo loading' />
    </motion.div>
  );
}
