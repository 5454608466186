export const fadeInAnimation = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 1,
    }
}

export const fadeOutAnimation = {
    animate: {
        opacity: 0,
    },
    exit: {
        opacity: 0,
    }
}
